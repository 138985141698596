<template>
  <div class="signup-template">
    <div class="row g-0 align-items-center cover-fit">
      <div class="col-lg-12 px-md-3 px-xl-5">
        <div class="px-3 pb-4 pt-5 p-md-5 text-center">

          <div class="login-form pt-md-5 mx-auto">
            <div class="mb-2 pb-md-1">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <p v-if="message" class="text-gray-700 pb-md-4 mb-4">{{ message }}</p></div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoadingView",
  props:['message']
};
</script>
<style scoped>
</style>
<template>
  <div class="signup-template bg-white">
    <div class="position-absolute top-0 start-0 p-4 p-md-5">

    </div>
    <div class="row g-0 align-items-center cover-fit">
      <div class="col-lg-12 px-md-3 px-xl-5">
        <div class="px-3 pb-4 pt-5 p-md-5 text-center">

          <div class="pt-4 pt-md-5 mx-auto">
            <h2 class="h1 mb-2 pb-md-1">Not Found</h2>
            <p class="text-gray-700 pb-md-4 mb-4">We cannot find the application you are looking for.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
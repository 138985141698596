import axios from 'axios';
import store from '../store/index';
import router from '../router/index';

const api_url = process.env.VUE_APP_API_URL;
async function getInstance(with_workspace)
{
    var workspace = store.state.workspace;
    if(workspace && workspace.id && with_workspace !== false)
    {
        return await store.dispatch('auth/getToken')
                          .then(token =>
                                {
                                    console.log(token);
                                    return createApiInstance("/workspaces/" + workspace.id, token)
                                }
                          )
                          .catch(() =>
                                 {
                                     store.dispatch("auth/logout")
                                          .then(() =>
                                                {
                                                    router.push({name: "login"});
                                                });
                                     return false;
                                 });
    }
    else
        return createApiInstance("/", false);
}
function createApiInstance(basePath, token)
{
    var api = axios.create({
        baseURL: api_url + basePath,
        timeout: 30000,
        headers: token ? {
            'Authorization': 'Bearer ' + token
        } : {},
        validateStatus: function (status) {
            return status > 0;
          }
    });

    api.interceptors.response.use((response) => {
        console.log(response.status);
        return response;
    }, (error) => {
        return Promise.reject(error);
    });
    return api;
}
export default {

    async get(path)
    {
        var instance = await getInstance(false);
        return instance.get(path);
    },

    async workspaceGet(path) {
        var instance = await getInstance();
        return instance.get(path);
    },
    async workspacePost(path, data) {
        var instance = await getInstance();
        return instance.post(path, data);
    },
    async workspacePatch(path, data) {
        var instance = await getInstance();
        return instance.patch(path, data);
    },
    async workspaceDelete(path) {
        var instance = await getInstance();
        return instance.delete(path);
    },

    async workspacePut(path, data) {
        var instance = await getInstance();
        return instance.put(path, data);
    }
}
<template>
<div class="alert alert-warning w-100 d-block m-0 rounded-0 d-flex align-items-center justify-content-between">
        <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle me-2" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
        </svg>
            Since your last interaction we’ve released fresh updates for the platform. Please refresh the page to continue.
          </span>
          <button class="btn btn-primary" @click="reload">Refresh</button>
      </div>
</template>
<script>
export default
{
  methods:{
    reload(){
      window.location.reload();
    }
  }
}
</script>
<style scoped>
.alert
{
  z-index:999999 !important;
  position: sticky;
}
</style>
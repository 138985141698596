<template>
  <app-outdated v-if="is_outdated" />
  <div v-if="ready">
    <Suspense>
      <template #default>
        <router-view/>
      </template>
      <template #fallback>
        <span>Loading...</span>
      </template>
    </Suspense>
    <CookieBanner/>
  </div>
  <workspace-not-found-view v-else-if="failed" />
  <LoadingView v-else/>
  <html-meta />
</template>
<script>

import CookieBanner from "@/components/interactive/CookieBanner";
import LoadingView from "@/views/LoadingView";
import Api from "@/services/Api";
import WorkspaceNotFoundView from "@/views/WorkspaceNotFoundView";
import HtmlMeta from "@/components/util/HtmlMeta";
import {mapGetters} from "vuex";
import AppOutdated from "@/components/interactive/AppOutdated.vue";
export default {
  components: {
    AppOutdated,
    HtmlMeta,
    WorkspaceNotFoundView,
    LoadingView,
    CookieBanner
  },
  data()
  {
    return {
      ready: false,
      failed: false,
      whitelabel: {
        logo: "url(/assets/svg/rocketer/logo.svg)",
        icon: "url(/assets/svg/rocketer/icon.svg)",
        color: "#0D2C88",
        highlight: "#2a53a4",
      }
    }
  },
  computed:{
    ...mapGetters({workspace:"getWorkspace", auth_redirecting:"auth/isRedirecting", is_outdated:"isOutdated"})
  },
  created()
  {
    if(!this.auth_redirecting)
      this.fetchWorkspace();
  },
  watch:{
    whitelabel(){
      this.whitelabel.logo = "url('" + this.whitelabel.logo + "')";
      this.whitelabel.icon = "url('" + this.whitelabel.icon + "')";
    },
    workspace()
    {
      if(this.workspace && this.workspace.style)
        this.whitelabel = Object.assign({}, this.workspace.style);
    }
  },
  methods: {
    async fetchWorkspace()
    {
      var domain = window.location.hostname;

      if(domain == process.env.VUE_APP_DOMAIN_SUFFIX && process.env.VUE_APP_DOMAIN_SUFFIX_REDIRECT)
      {
        window.location.href = process.env.VUE_APP_DOMAIN_SUFFIX_REDIRECT;
        return;
      }

      await Api.get("/platforms/" + domain)
               .then(response =>
                     {
                       if(response.status == 200)
                       {
                         this.$store.commit('setWorkspace', response.data);
                         this.ready = true;
                       }
                       else
                       {
                         this.failed = true;
                       }
                     }, err =>
                     {
                       console.log(err);
                       this.failed = true;
                     });
    }
  }
}
</script>
<style>
* {
  --primary-colour: v-bind('whitelabel.color');
  --primary-colour-highlight: v-bind('whitelabel.highlight');
  --primary-colour-highlight-border: v-bind('whitelabel.highlight');
  --primary-colour-text-colour: #FFF;
  --img-logo: v-bind('whitelabel.logo');
  --img-icon: v-bind('whitelabel.icon');
}

.company-logo {
  background-image: var(--img-logo);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* Theme settings */
* {
  --platform-blue: #1A194C;
}

.bg-platform {
  background-color: var(--platform-blue) !important;
}

.input-group.border-gray-300:hover {
  border-color: #1e1e1e !important;
}

.form-control[type=text]:hover, .form-control[type=password]:hover, .form-control[type=number]:hover {
  border-color: #1e1e1e !important;
}

.muze-dark-mode .input-group.border-gray-300:hover {
  border-color: #fff !important;
}

.muze-dark-mode .form-control[type=text]:hover, .form-control[type=password]:hover, .form-control[type=number]:hover {
  border-color: #fff !important;
}
</style>

import Api from "@/services/Api";

export const report = {
    namespaced: true,
    state: {
        data: null,
        brand_id: null
    },
    mutations: {
        setData(state, object)
        {
            state.data     = object.data;
            state.brand_id = object.brand_id;
        }
    },
    getters: {
        getCurrentData(state)
        {
            return state.data;
        },
        getCurrentDataBrandId(state)
        {
            return state.brand_id;
        }
    },
    actions: {
        clearData({commit})
        {
           commit("setData", {data: false, brand_id: false});
        },
        getReportData({commit, state}, brand_id)
        {
            if(brand_id == state.brand_id)
                return state.data;
            commit("setData", {data: false, brand_id: false});
            return new Promise( (success, failure) =>
                               {
                                   Api.workspaceGet("reports/campaign?brand=" + brand_id)
                                            .then(
                                                    response =>
                                                    {
                                                        commit("setData", {data: response.data, brand_id: brand_id});
                                                        success(response.data);
                                                    },
                                                    err =>
                                                    {
                                                        console.log(err);
                                                        failure(false);
                                                    }
                                            );
                               });
        }
    }
}
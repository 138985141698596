import { Amplify, Auth, Hub } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { launchUri } from '@aws-amplify/auth/lib-esm/OAuth/urlOpener';

import {CookieStorage} from "amazon-cognito-identity-js";


var AmazonCognitoIdentity = require('amazon-cognito-identity-js')

var cookieSettings = {
    domain: process.env.VUE_APP_AUTHENTICATION_COOKIE_DOMAIN,
    secure: !process.env.VUE_APP_AUTHENTICATION_COOKIE_DOMAIN.includes("localhost"),
    path: '/',
    expires: 365,
};
var cookieStorage = new CookieStorage(cookieSettings);

var redirecting = false;
if(cookieStorage.getItem('oauth_redirect'))
{
    redirecting = true;
    var url = cookieStorage.getItem('oauth_redirect');
    cookieStorage.removeItem('oauth_redirect');
    launchUri(url.split('?')[0] + location.search);
}

var poolData = {
    UserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID, // Your user pool id here
    ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID, // Your client id here,
    Storage: cookieStorage,
};

var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

Hub.listen('auth', ({payload: {event}}) => {
    if (event == "parsingCallbackUrl")
    {
        window.sessionStorage.setItem('oauth_state', cookieStorage.getItem('oauth_state'));
        window.sessionStorage.setItem('ouath_pkce_key', cookieStorage.getItem('ouath_pkce_key'));
    }
});

export const auth = {
    namespaced: true,
    state: {
        cognitoUser: null,
        amplifyConfigured:false,
        userDetails:{},
        redirecting:redirecting
    },
    mutations: {
        setCognitoUser(state, user) {
            state.cognitoUser = user;
        },
        logout(state) {
            state.cognitoUser = null;
            state.userDetails = {};
        },
        setUserDetails(state, details)
        {
            state.userDetails = {
                email: details.email ?? null,
                name: details.name ?? null
            };
        },
        configureAmplify(state)
        {
            if(state.amplifyConfigured !== true)
            {

                Amplify.configure({
                                      Auth: {
                                          region: "eu-west-2",
                                          userPoolId: "eu-west-2_boVD9Lv8w",
                                          userPoolWebClientId: "56migfbvm2kimdbhjuqnbqhg6c",
                                          mandatorySignIn: true,
                                          oauth: {
                                              domain: 'rocketer-platform.auth.eu-west-2.amazoncognito.com',
                                              scope: [
                                                  'email',
                                                  'profile',
                                                  'openid',
                                                  'aws.cognito.signin.user.admin'
                                              ],
                                              redirectSignIn: process.env.VUE_APP_AUTHENTICATION_REDIRECT_SIGNIN,
                                              redirectSignOut: process.env.VUE_APP_AUTHENTICATION_REDIRECT_SIGNOUT,
                                              responseType: 'code',
                                              options: {
                                                  urlOpener: function(url)
                                                  {
                                                      cookieStorage.removeItem('oauth_state');
                                                      cookieStorage.removeItem('ouath_pkce_key');
                                                      cookieStorage.setItem('oauth_state', window.sessionStorage.getItem("oauth_state"))
                                                      cookieStorage.setItem('ouath_pkce_key', window.sessionStorage.getItem("ouath_pkce_key"))
                                                      if(window.location.href != process.env.VUE_APP_AUTHENTICATION_REDIRECT_SIGNIN)
                                                          cookieStorage.setItem('oauth_redirect', window.location.href);
                                                      return launchUri(url);
                                                  }
                                              }
                                          },
                                          cookieStorage: cookieSettings
                                      }
                                  });

                state.amplifyConfigured = true;
            }
        }
    },
    getters: {
        isLoggedIn(state)
        {
            return state.cognitoUser != null;
        },
        loggedInUserDetails(state)
        {
            return state.userDetails;
        },
        getCognitoUser(state)
        {
            return state.cognitoUser;
        },
        isRedirecting(state)
        {
            return state.redirecting;
        }
    },
    actions: {
        getSession({commit, getters})
        {
            return new Promise((success, failure) => {
                var user = getters.getCognitoUser ?? userPool.getCurrentUser();
                commit("setCognitoUser", user);
                user.getSession((err, session) => {
                    if (!session || !session.isValid()) {
                        failure("User session not valid.");
                    } else {
                        success(session);
                    }
                });
            });
        },
        getToken({commit, dispatch})
        {
            if(!commit)
                return false;
            return new Promise((success, failure) => {
                dispatch('getSession').then(session => {
                    console.log(session.getIdToken().getJwtToken());
                    success(session.getIdToken().getJwtToken());
                }).catch(message => {
                    failure(message);
                });
            });
        },
        initiateFederation({commit}, provider)
        {
            commit("configureAmplify");
            switch(provider.toLowerCase())
            {
                case "google":
                    Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google });
                    break;
                case "facebook":
                    Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook });
                    break;
                default:
                    break;
            }
        },
        listenForFederation({commit}, callbacks)
        {
            var originalReplaceState = window.history.replaceState;
            window.history.replaceState = function() {}
            commit("configureAmplify");
            var emptyCallback = () => {};
            var loadingCallback = callbacks.loadingCallback ?? emptyCallback;
            var successCallback = callbacks.successCallback ?? emptyCallback;
            var errorCallback = callbacks.errorCallback ?? emptyCallback;
            function getUser() {
              return Auth.currentAuthenticatedUser()
                .then(userData => userData)
                .catch(() => console.log('Not signed in'));
            }


            Hub.listen('auth', ({ payload: { event } }) => {
                switch (event) {
                    case 'signIn':
                    case 'cognitoHostedUI':
                        window.history.replaceState = originalReplaceState;
                        loadingCallback();
                        getUser().then(userData => {
                            if(!userData)
                                return false;
                            commit('setCognitoUser', userPool.getCurrentUser())
                            if(cookieStorage.getItem('oauth_redirect'))
                            {
                                var url = cookieStorage.getItem('oauth_redirect');
                                cookieStorage.removeItem('oauth_redirect');
                                launchUri(url)
                            }
                            else
                            {
                                successCallback();
                            }
                        });
                      break;
                    case 'signIn_failure':
                    case 'cognitoHostedUI_failure':


                      errorCallback("External authentication has failed.");
                      break;
                  }
                });
        },
        logout({commit}) {
            return new Promise((success) => {
                if(userPool.getCurrentUser()) {
                    try
                    {
                        let coguser = userPool.getCurrentUser();
                        if(coguser != null)
                        {
                            coguser.signOut(() =>
                                            {
                                                commit("logout");
                                                success(true);
                                            });
                        }
                    }
                    catch(e)
                    {
                        commit("logout");
                        success(true);
                    }

                }
                else
                {
                    commit("logout");
                    success(true);
                }
            });

        },
        fetchLoggedInUserDetails({commit, dispatch})
        {
            return new Promise((success, failure) => {
               dispatch('getSession').then((session) => {
                   var details = (({ name, email }) => ({ name, email }))(session.getIdToken().payload);
                    commit("setUserDetails", details);
                    success(details);
               }).catch(message => {
                   console.log(message);
                   failure(message);
               });
            });
        },
        loginWithCredentials({commit}, user) {

            return new Promise((success, failure) => {
                var authenticationData = {
                    Username: user.username,
                    Password: user.password,
                    Pool: userPool,
                    Storage: cookieStorage,
                };

                var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

                var cognitoUser = new AmazonCognitoIdentity.CognitoUser(authenticationData);
                cognitoUser.authenticateUser(authenticationDetails, {
                    onSuccess: function () {
                        commit('setCognitoUser', cognitoUser)
                        success(true);
                    },
                    onFailure: function (err) {
                        if(err.code == "UserNotConfirmedException")
                           failure("Email verification required")
                        else
                            failure(err.message ? err.message : "Unknown error");
                    },
                });
            });
        },
        register({commit}, user) {

            if(!commit)
                return false;

            return new Promise((success, failure) => {
                userPool.signUp(user.username, user.password, [new AmazonCognitoIdentity.CognitoUserAttribute({Name: 'name', Value: user.name})],null,function(err){
                    if(!err)
                    {
                        success(true);
                    }else{
                        var message = "Unknown error";
                        if(err.code == "UsernameExistsException")
                            message = "Email address already in use";
                        else if(err.code == "InvalidPasswordException")
                            message = "Password does not meet policy";

                        return failure(message);
                    }
                });
            });
        },
        confirmEmail({commit}, user)
        {
            if(!commit)
                return false;

            return new Promise((success, failure) => {
                var authenticationData = {
                    Username: user.username,
                    Pool: userPool,
                    Storage: cookieStorage,
                };
                var cognitoUser = new AmazonCognitoIdentity.CognitoUser(authenticationData);
                cognitoUser.confirmRegistration(user.code, true, (err) => {
                   if(err)
                   {
                       failure("Invalid verification code or it has expired.");
                   }
                   else
                   {
                       success(true);
                   }
                });
            });
        },
        confirmEmailResend({commit}, username)
        {
            if(!commit)
                return false;

            return new Promise((success, failure) => {
                var authenticationData = {
                    Username: username,
                    Pool: userPool,
                    Storage: cookieStorage,
                };
                var cognitoUser = new AmazonCognitoIdentity.CognitoUser(authenticationData);
                cognitoUser.resendConfirmationCode( (err) => {
                   if(err)
                   {
                       failure("Unable to send another code.");
                   }
                   else
                   {
                       success(true);
                   }
                });
            });
        },
        forgotPassword({commit}, username)
        {
            if(!commit)
                return false;

            return new Promise((success, failure) => {
                var authenticationData = {
                    Username: username,
                    Pool: userPool,
                    Storage: cookieStorage,
                };
                var cognitoUser = new AmazonCognitoIdentity.CognitoUser(authenticationData);
                cognitoUser.forgotPassword({
                    onSuccess: function () {
                        success(true);
                    },
                    onFailure: function (err) {
                        return failure(err.message ? err.message : "Unknown error");
                    },
                });
            });
        },
        confirmPassword({commit}, user)
        {
            if(!commit)
                return false;

            var authenticationData = {
                    Username: user.username,
                    Pool: userPool,
                    Storage: cookieStorage,
            };
            var cognitoUser = new AmazonCognitoIdentity.CognitoUser(authenticationData);
            return new Promise((success, failure) => {
                cognitoUser.confirmPassword(user.code, user.password, {
                    onSuccess: function () {
                        success(true);
                    },
                    onFailure: function (err) {
                        return failure(err.message ? err.message : "Unknown error");
                    },
                });
            });
        }
    }
}

auth.state.cognitoUser = userPool.getCurrentUser();
<template>
  <div></div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({workspace: "getWorkspace"})
  },
  methods:
      {
        buildTitle()
        {
          let to = this.$route;
          if(this.workspace && this.workspace.name)
          {
            if(to && to.meta && to.meta.title)
            {
              document.title = this.workspace.name + " - " + to.meta.title;
            }
            else
              document.title = this.workspace.name;
          }
        }
      },
  watch: {
    workspace()
    {
      // Page Title
      document.title = this.workspace.name;

      // Favicon
      var link  = document.querySelector("link[rel~='icon']");
      link.href = this.workspace.style.icon;

      this.buildTitle();
    },
    $route: {
      handler()
      {
        this.buildTitle();
      },
      immediate: true
    }
  }
}
</script>